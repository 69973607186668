import React from 'react';
import logo from '../assets/logo.svg';


import {
  Link
} from "react-router-dom";

function Header(props) {
	return (
    <header className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-start">
        <Link to="/">
          <img src={logo} className="logo" alt="iExec" referrerPolicy="no-referrer" />
      </Link>
      <p className="titre_head">{props.titre}</p>
      </div>
    </header>
  );
}

export default Header;
