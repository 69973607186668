import React, {useState, useEffect} from 'react';
import {
  withRouter
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { API_URL  } from '../config';
import ReactTooltip from 'react-tooltip';

import Header from '../components/header';

// <button className={"btn_round anime "+((userDetails.synaps_status === 1 || userDetails.synaps_status === 0 || userDetails.synaps_status === undefined) ? 'hide' : 'show')} >Download KYC</button>

function Admin(props) {

  const [alertVisible, setAlertVisible] = useState(0);
  const [message, setMessage] = useState("Error !");

  const [kycLoad, setKycLoad] = useState(0);


  useEffect(() => {
    if(alertVisible) {
    const timer = setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
    }
  }, [alertVisible]);


  const [alertVisible2, setAlertVisible2] = useState(0);
  const [message2, setMessage2] = useState("Mail sent !");


  useEffect(() => {
    if(alertVisible2) {
    const timer2 = setTimeout(() => {
      setAlertVisible2(false);
    }, 5000);
    }
  }, [alertVisible2]);

  const Ligne = ({
    wallet,
    mail,
    payment,
    whiteliste,
    synaps_status,
  }) => (
    <tr>

      <th scope="row"><i className="fas fa-search" onClick={() => detail(wallet)}></i></th>
      <td>{wallet}</td>
      <td>{mail}</td>
      <td><i className={"fas fa-circle synaps_status_"+(synaps_status)}></i></td>
      <td><i className={"fas fa-circle "+(payment ? 'green' : 'red')}></i></td>
      <td><i className={"fas fa-circle "+(whiteliste ? 'green' : 'red')}></i></td>
      <td>
        <ReactTooltip />
        <i className={"fas fa-check "+((synaps_status === 2 && payment === true &&  !whiteliste)  ? 'show' : 'hide')} data-tip="Allow user" onClick={() => valide(wallet, true, mail)}></i>
        <i className={"fas fa-times "+(whiteliste ? 'show' : 'hide')} data-tip="Disallow user" onClick={() => valide(wallet, false, mail)}></i></td>
    </tr>
  );

  const valide = (adr, val, mail) => {
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "adr":adr,
      "val":val
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/user/whitelist');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          refresh_list();
          setUsersDetails({
            ...userDetails,
            white_list : val
          });
          if(val === true) {
            send_mail(mail, adr, "KYC_OK");
          }

        }
      }
    }
  }


  const send_mail = (mail, adr, type) => {
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "mail":mail,
      "adr":adr
    }

    if(type === "REJECTED") {
      var log_api = new XMLHttpRequest();
      log_api.open('POST', API_URL+'/mail/rejected');
      log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
      log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
      log_api.send(JSON.stringify(params));
      log_api.onreadystatechange = function() {
        if(log_api.readyState === 4) {
          if(log_api.status === 200) {
            if(log_api.response === "OK") {
              setMessage2("Mail sent !");
              setAlertVisible2(1);
            }else {
              setMessage("Error !");
              setAlertVisible(1);
            }
          }
        }
      }
    }else if(type === "KYC_OK") {
      var log_api = new XMLHttpRequest();
      log_api.open('POST', API_URL+'/mail/kyc-ok');
      log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
      log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
      log_api.send(JSON.stringify(params));
      log_api.onreadystatechange = function() {
        if(log_api.readyState === 4) {
          if(log_api.status === 200) {
            if(log_api.response === "OK") {
              setMessage2("Mail sent !");
              setAlertVisible2(1);
            }else {
              setMessage("Error !");
              setAlertVisible(1);
            }
          }
        }
      }
    }


  }


  const check_kyc = () => {
    setKycLoad(1);

    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var check_synaps = new XMLHttpRequest();
    check_synaps.open('POST', API_URL+'/admin/synaps/check_admin');
    check_synaps.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    check_synaps.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    check_synaps.send(JSON.stringify(params));
    check_synaps.onreadystatechange = function() {
      if(check_synaps.readyState === 4) {
        if(check_synaps.status === 200) {
          var response = JSON.parse(check_synaps.response);
          if(response.result === "OK") {
            setMessage2("All KYC checks completed");
            setAlertVisible2(1);
            refresh_list();

            setKycLoad(0);
          }else {
            setMessage("Authentification failed !");
            setAlertVisible(1);
            setKycLoad(0);
          }
        }
      }
    }
  }

  const valide_identity = (adr) => {
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "adr":adr,
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/user/identity');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          setUsersDetails({
            ...userDetails,
            synaps_status : 2
          });
        }else {
          console.log("Error !");
        }
      }
    }
  }

  const detail = (adr) => {
    setRedirect(1);
    //setUsersDetails({eth:adr});
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "adr":adr
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/user/detail');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          //setListUsers(response);
          setUsersDetails({
            eth:response[0].eth,
            type_compte : response[0].type_compte,
            synaps : response[0].synaps,
            score_chain : response[0].score_chain,
            mail : response[0].mail,
            how_much_invest : response[0].how_much_invest,
            honor : response[0].honor,
            payment : response[0].payment,
            payment_id : response[0].payment_id,
            white_list : response[0].white_list,
            synaps_status : response[0].synaps_status,
          });
        }
      }
    }
  }

  useEffect(() => {
    //let history = useHistory();

    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/check-admin');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status !== 200) {
          go_home();
        }else {
          refresh_list();
        }
      }
    }



  }, []);

  let history = useHistory();

  function go_home() {
    history.push("/login");
  }

  function refresh_list() {
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/users');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          setListUsers(response);
          //console.log(response);
        }
      }
    }
  }

  function back_list() {
    refresh_list();
    setRedirect(0);
  }


    const [redirect, setRedirect] = useState(0);
    const [listUsers, setListUsers] = useState([]);
    const [userDetails, setUsersDetails] = useState({
      eth : '',
      type_compte : 0,
      synaps : "",
      score_chain : "",
      mail : "",
      how_much_invest : "",
      honor : 0,
      payment : false,
      payment_id : "",
      white_list : false,
      synaps_status : 0
    });
    const type_compte = ["Aucun", "Individual", "Company"];
    const [session_synaps, setSession_synaps] = useState("");


	  if (redirect) {
		  return (
        <div>
        <Header titre="Enterprise Marketplace" balance="" />

        <div className="welcome_screen center">
          <h1 className="h1_welcome center"><i className="fas fa-chevron-left retour_liste" onClick={() => back_list()}></i> User : {userDetails.eth}</h1>
          <div className="user_detail">
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>Mail :</p>
              <p className="bold">{userDetails.mail}</p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>Type :</p>
              <p className="bold">{type_compte[userDetails.type_compte]}</p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>ScoreChain :</p>
              <p className="bold">{userDetails.score_chain}/100</p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>How much invest :</p>
              <p className="bold">{userDetails.how_much_invest}</p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>Synaps status :</p>
              <p className="bold"><i className={"fas fa-circle synaps_status_"+(userDetails.synaps_status)}></i></p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>Payment :</p>
              <p className="bold"><i className={"fas fa-circle "+(userDetails.payment ? 'green' : 'red')}></i></p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>Payment id :</p>
              <p className="bold">{userDetails.payment_id}</p>
            </div>
            <div className="FormRowLog d-flex align-items-center justify-content-between">
              <p>White list :</p>
              <p className="bold">
                <i className={"fas fa-circle "+(userDetails.white_list ? 'green' : 'red')}></i>
              </p>
            </div>
            <div className="FormRowLog">
              <button className={"btn_round anime "+((userDetails.synaps_status === 2 && userDetails.payment === true &&  !userDetails.white_list)  ? 'show' : 'hide')} onClick={() => valide(userDetails.eth, !userDetails.white_list, userDetails.mail)}>Allow user</button>
              <button className={"btn_round anime "+(userDetails.white_list ? 'show' : 'hide')} onClick={() => valide(userDetails.eth, !userDetails.white_list, userDetails.mail)}>Disallow user</button>
            </div>

          </div>
          <div className="FormRowLog">
          <button className={"btn_round anime "+((userDetails.mail === "") ? 'hide' : '')} onClick={() => send_mail(userDetails.mail, userDetails.eth, "REJECTED")}>Send mail : user rejected</button>
        </div>
        </div>
        <div className={"alert anime "+(!alertVisible ? 'cache' : '')}><p><i className="fas fa-exclamation"></i> <b>Failed :</b> {message}</p></div>
        <div className={"alert2 anime "+(!alertVisible2 ? 'cache' : '')}><p><i className="fas fa-check"></i> <b>Succes :</b> {message2}</p></div>
      </div>
      );
	}else {
    return (
	    <div>
		    <Header titre="Enterprise Marketplace" balance="" />
			<div className="welcome_screen center">
				<h1 className="h1_welcome center">Users</h1>
        <div className="table d-flex align-items-center justify-content-start mb-4">
        <button className={"btn_round anime "} onClick={() => check_kyc()}>Check all KYC</button>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={"svg-inline--fa fa-sync-alt fa-w-16 fa-spin fa-lg "+(!kycLoad ? 'cache' : '')}><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z" className=""></path></svg>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Wallet</th>
              <th scope="col">Mail</th>
              <th scope="col">Identity</th>
              <th scope="col">Payment</th>
              <th scope="col">White list</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {listUsers && listUsers.map((un_user, index) => (
              <Ligne key={un_user.eth} wallet={un_user.eth} mail={un_user.mail} payment={un_user.payment} whiteliste={un_user.white_list} synaps_status={un_user.synaps_status}  />
            ))}
          </tbody>
        </table>
			</div>
      <div className={"alert anime "+(!alertVisible ? 'cache' : '')}><p><i className="fas fa-exclamation"></i> <b>Failed :</b> {message}</p></div>
      <div className={"alert2 anime "+(!alertVisible2 ? 'cache' : '')}><p><i className="fas fa-check"></i> <b>Succes :</b> {message2}</p></div>
		</div>
    );
    }

}

export default withRouter(Admin);
