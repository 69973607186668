import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { API_URL, API_STRIPE } from '../config';

import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import '../css/stripe.css';


import Header from '../components/header';
import Warning from '../components/warning';
import Etapes from '../components/head_etapes';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#30405880',
      color: '#30405880',
      fontWeight: 800,
      fontFamily: '"Lato",sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      border: '1px solid #CBCBCB',
      ':-webkit-autofill': {
        color: '#30405880',
      },
      '::placeholder': {
        color: '#30405880',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const CardField = ({onChange}) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({processing, error, children, disabled}) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
);

const ErrorMessage = ({children}) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({onClick}) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {

      var params = {
        "adr_eth" : localStorage.getItem('adr_eth'),
        "payment_method_id":payload.paymentMethod.id
      }

      var log_api = new XMLHttpRequest();
      log_api.open('POST', API_URL+'/payment');
      log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
      log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
      log_api.send(JSON.stringify(params));
      log_api.onreadystatechange = function() {
        if(log_api.readyState === 4) {
          if(log_api.status === 200) {
            console.log(log_api.response);
            var response = JSON.parse(log_api.response);
            //setPaymentMethod(payload.paymentMethod);
            //console.log(payload.paymentMethod.id);

            //setRedirect(1);
            if(response.status === "requires_action") {
              setPaiement_id(response.id);
              var params = {
                "adr_eth" : localStorage.getItem('adr_eth'),
                "payment_method_id":response.id
              }
              var log_api_intent = new XMLHttpRequest();
              log_api_intent.open('POST', API_URL+'/intent');
              log_api_intent.setRequestHeader('Authorization', localStorage.getItem("token_user"));
              log_api_intent.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
              log_api_intent.send(JSON.stringify(params));
              log_api_intent.onreadystatechange = function() {
                if(log_api_intent.readyState === 4) {
                  if(log_api_intent.status === 200) {
                    var response = JSON.parse(log_api_intent.response);
                    //setSecure_paiement({__html:"<iframe src='"+ response.next_action.redirect_to_url.url +"'></iframe>"})
                    window.location = response.next_action.redirect_to_url.url;
                    //history.push(response.next_action.redirect_to_url.url );
                  }else {
                  console.log("Error !");
                  }
                }
              }
              //setSecure_paiement({__html:"<iframe src='"+ response.next_action.use_stripe_sdk.stripe_js +"'></iframe>"})
              //console.log(response);

            }else if(response.status === "succeeded") {
              //console.log(JSON.parse(log_api.response));
              setPaymentMethod(payload.paymentMethod);
              //setRedirect(1);
            }
          }else {
            console.log("Error !");

          }
        }
      }
    }
  };

  const close_popup = () => {
    setShow_secure_paiement(false);
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "payment_method_id":paiement_id
    }

    var log_api_intent = new XMLHttpRequest();
    log_api_intent.open('POST', API_URL+'/intent');
    log_api_intent.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api_intent.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api_intent.send(JSON.stringify(params));
    log_api_intent.onreadystatechange = function() {
      if(log_api_intent.readyState === 4) {
        if(log_api_intent.status === 200) {
          console.log(JSON.parse(log_api_intent.response));
        }else {
        console.log("Error !");
        }
      }
    }
  }

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: '',
      phone: '',
      name: '',
    });
  };

  const [secure_paiement, setSecure_paiement] = useState({__html:''});
  const [paiement_id, setPaiement_id] = useState();
  const [show_secure_paiement, setShow_secure_paiement] = useState(false);

  return paymentMethod ? (
    <div className="Result full_screen_2">
      <h1 className="bold">Payment Accepted, thank you !</h1>
      <a href="/swap-erlc" className={"btn_round anime "} >Next</a>
    </div>
  ) : (

    <form className="Form" onSubmit={handleSubmit}>
    <div className={"iframe_paiement "+(!show_secure_paiement ? 'hide' : '')}>

      <div className="cont_iframe">
        <div className="btn_close_iframe" onClick={() => close_popup()}>
          <i className="fas fa-times"></i>
        </div>
        <div className="iframe" dangerouslySetInnerHTML={secure_paiement}></div>
      </div>
    </div>
    <div className={"ph_top center "}>
    <h1 className="h1_welcome center">Banking Verification</h1>
    <p><i>This payment will prove that you have the ownership of a bank account,<br />and help us to conduct the full KYC/KYB of your profile.</i></p>
  </div>
      <fieldset className="FormGroup">
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({...billingDetails, name: e.target.value});
          }}
        />
        <Field
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({...billingDetails, email: e.target.value});
          }}
        />
        <Field
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({...billingDetails, phone: e.target.value});
          }}
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Pay €1
      </SubmitButton>
    </form>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const stripePromise = loadStripe(API_STRIPE);

function Payment(props) {


  useEffect(() => {
    localStorage.setItem('pay', 0);
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/check');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status !== 200) {
          go_home();
        }
      }
    }

    let url = props.location.search;
    let params_url = queryString.parse(url);
    console.log(params_url);
    if(params_url.payment_intent_client_secret) {
      check_paiement_secure(params_url.payment_intent_client_secret);
    }
  }, []);

  let history = useHistory();

  function go_home() {
    history.push("/");
  }

  async function check_paiement_secure(payment_intent_client_secret) {
    const stripe = await loadStripe(API_STRIPE);

    try {

      stripe.retrievePaymentIntent(payment_intent_client_secret)
      .then(function(result) {
        if (result.error) {
          // PaymentIntent client secret was invalid
          //setEtape(1);
          console.log("Error !!!")
        } else {
          console.log(result);
          if (result.paymentIntent.status === 'succeeded') {
            // Show your customer that the payment has succeeded
            setEtape(1);
          }else {
            // Authentication failed, prompt the customer to enter another payment method
            //setEtape(1);
            console.log(result.paymentIntent.status);
          }
        }
      });
    }catch(e) {
      console.log(e);
    }


  }

  const [etape, setEtape] = useState(0);


  return (
    <div>
      <Header titre="Enterprise Marketplace" balance="" />
      <Etapes pos="4" />


      <div className={"payment_screen center "+(etape === 0 ? 'show' : 'hide')}>

        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm />
        </Elements>

      </div>

      <div className={"payment_screen center "+(etape === 1 ? 'show' : 'hide')}>
        <h1 className="succes">Payment Accepted, thank you !</h1>
        <a href="/swap-erlc" className="btn_round anime">Next</a>
      </div>


      <Warning show="0" />
    </div>
  );

}

export default withRouter(Payment);
