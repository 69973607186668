import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



import './css/index.css';

import Welcome from './components/welcome';

import Authentification from './components/authentification';
import Identity from './components/identity';
import Others_infos from './components/others_infos';
import Payment from './components/payment';
import Swap from './components/swap';
import Login from './components/login';
import Admin from './components/admin';

function App() {
    return (
		<Router>
			<div>
				<Switch>

					<Route path="/" exact component={Welcome}></Route>

          <Route path="/authentification" exact component={Authentification}></Route>
          <Route path="/identity" exact component={Identity}></Route>
          <Route path="/additional-informations" exact component={Others_infos}></Route>
          <Route path="/payment-verification" exact component={Payment}></Route>
          <Route path="/swap-erlc" exact component={Swap}></Route>

          <Route path="/login" exact component={Login}></Route>
          <Route path="/admin" exact component={Admin}></Route>

          <Route component={Authentification} />
				</Switch>
			</div>
		</Router>
    );

}

export default App;
