import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import { API_URL } from '../config';

import Header from '../components/header';

function Welcome(props) {


    const [redirect, setRedirect] = useState(0);
    const [login, setLogin] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState(0);
    let history = useHistory();

    const log = () => {
      var params = {
        "login" : login,
        "pass" : pass
      }

      var log_api = new XMLHttpRequest();
      log_api.open('POST', API_URL+'/admin');
      log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
      log_api.send(JSON.stringify(params));
      log_api.onreadystatechange = function() {
        if(log_api.readyState === 4) {
          if(log_api.status === 200) {
            var response = JSON.parse(log_api.response);
            localStorage.setItem('adr_eth', response.adr_eth);
            localStorage.setItem('token_user', response.token);
            history.push("/admin");
          }else {
            setError(1);
          }
        }
      }
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        log();
      }
    }


	  if (redirect) {
		  return <Redirect push to="/" />;
	}else {

    return (
	    <div>
		    <Header titre="Enterprise Marketplace" balance="" />
			<div className="welcome_screen center full_screen log_screen">
				<h1 className="h1_welcome center">Manage accounts</h1>
        <form className="form_log">
          <div className="FormRowLog d-flex align-items-center justify-content-between">
            <label htmlFor={"login"} className="FormRowLabelLog">Login</label>
            <input
              className="FormRowInputLog"
              id={"login"}
              type={"text"}
              required={true}
              value={login}
              onChange={e => setLogin(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="FormRowLog d-flex align-items-center justify-content-between">
          <label htmlFor={"password"} className="FormRowLabelLog">Password</label>
          <input
            className="FormRowInputLog"
            id={"password"}
            type={"password"}
            required={true}
            value={pass}
            onChange={e => setPass(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        </form>

        <a href="#" className="btn_round anime" onClick={() => log()}>Login</a>

        <div className={"error bg-danger "+(error ? 'show' : 'hide')}>Error !</div>
			</div>
      <div className="img_illustration"></div>
		</div>
    );
    }

}

export default withRouter(Welcome);
