import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import { API_URL } from '../config';

import Header from '../components/header';
import Warning from '../components/warning';
import Etapes from '../components/head_etapes';


function Swap(props) {

useEffect(() => {
  //let history = useHistory();

  var params = {
    "adr_eth" : localStorage.getItem('adr_eth')
  }

  var log_api = new XMLHttpRequest();
  log_api.open('POST', API_URL+'/check');
  log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
  log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
  log_api.send(JSON.stringify(params));
  log_api.onreadystatechange = function() {
    if(log_api.readyState === 4) {
      if(log_api.status !== 200) {
        go_home();
      }
    }
  }

}, []);

let history = useHistory();

function go_home() {
  history.push("/");
}



return (
  <div>
    <Header titre="Enterprise Marketplace" balance="" />
    <Etapes pos="5" />


    <div className="center end_screen full_screen_2">
      <h1 className="h1_welcome center">Thank you!</h1>

      <p><i>After receiving the confirmation e-mail,<br />you will be able to connect to the eRLC swap platform</i></p>

      <a href="https://swap.enterprise.iex.ec/" className="btn_round anime">Go to the eRLC swap platform</a>
    </div>


    <Warning show="0" />
  </div>
);

}

export default withRouter(Swap);
