import React, {useState, useEffect} from 'react';
import MyPDF from '../assets/iExec_Enterprise_Marketplace.pdf';

function Warning(props) {

	const [show_warning, setShow_warning] = useState(parseInt(props.show));

  const click_close = (etat) => {
    setShow_warning(etat);
  }


	return (
    <div className="cont_warning">
    <div className="warning anime" onClick={() => click_close(1)}>
      <p className="bold">Warning</p>
    </div>

    <div className={"popup_warning anime "+(!show_warning ? 'cache' : '')} >
    <i className="far fa-window-close close anime" onClick={() => click_close(0)}></i>
    <p className="bold">General warning</p>

    <p>Investment in an initial coin offering as defined in Article L. 552-3 of the Monetary and Financial Code entails risks of a partial or complete loss of the investment. No guarantee is given regarding the liquidity of the tokens acquired in the offering, the existence of a secondary market for said tokens, the value of the tokens acquired in the offering and the exchange value of said tokens in legal currency. Tokens do not constitute financial instruments within the meaning of Article L. 211-1 of the Monetary and Financial Code and confer no other right than those described in the information document. In addition, the regulatory framework applicable to the offering and to the tokens as well as the tax regime applicable to the holding of tokens are not defined to date in certain jurisdictions. </p>

    <p>The approval issued by the AMF concerns only the offering covered by this information document. As from the end of the offering, the AMF will not monitor the issuer or its project. Any communications subsequent to the offering and relating thereto will not be reviewed by the AMF. </p>

    <p>The subscriber is invited to read section 4 “risk factors” of the information document.</p>
    <a href={MyPDF} download="iExec_Enterprise_Marketplace.pdf">Download the information document</a>
    </div>
    </div>
  );
}

export default Warning;
