import React, {useState, useEffect} from 'react';
import {
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';

import Header from '../components/header';
import Warning from '../components/warning';
import Etapes from '../components/head_etapes';

import { API_URL} from '../config';


function Authentification(props) {

  const [redirect, setRedirect] = useState(0);
  const [metamask_find, setMetamask_find] = useState(0);
  const [metamask_req, setMetamask_req] = useState([]);
  const [rdm, setRdm] = useState("");

  const [alertVisible, setAlertVisible] = useState(0);
  const [message, setMessage] = useState("Metamask must be connected to the main Ethereum network");

  useEffect(() => {
    if(alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    }
  }, [alertVisible]);


  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setMetamask_find(1);
    }

    var log_api = new XMLHttpRequest();
    log_api.open('GET', API_URL+'/metamask');
    log_api.send();
    log_api.onload = function() {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          setRdm(response.rdm);
          setMetamask_req(response.param);
        }else {
          console.log("Error !");
        }
    }

  }, []);

  async function getAccount() {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];

    var params = [account, metamask_req];

    try {
      const transactionHash = await window.ethereum.request({
        method: 'eth_signTypedData_v4',
        params: params
      });
      var params = {
        "signature": transactionHash,
        "random":rdm
      }

      var log_api = new XMLHttpRequest();
      log_api.open('POST', API_URL+'/login');
      log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
      log_api.send(JSON.stringify(params));
      log_api.onreadystatechange = function() {
        if(log_api.readyState === 4) {
          if(log_api.status === 200) {
            var response = JSON.parse(log_api.response);
            localStorage.setItem('adr_eth', response.user[0].eth);
            localStorage.setItem('token_user', response.token);
            if(response.user[0].step === 2) {
              setRedirect(1);
            }else if(response.user[0].step === 3) {
              setRedirect(2);
            }else if(response.user[0].step === 4) {
              setRedirect(3);
            }else if(response.user[0].step === 5) {
              setRedirect(4);
            }
          }else {
            console.log("Error !");
          }
        }
      }


    } catch (error) {
      if(error.code === -32603) {
        setAlertVisible(1);
      }else {
        console.error(error);
      }

    }
  }




	  if (redirect === 1) {
		  return <Redirect push to="/identity" />;
	}else if(redirect === 2) {
      return <Redirect push to="/additional-informations" />;
  }else if(redirect === 3) {
      return <Redirect push to="/payment-verification" />;
  }else if(redirect === 4) {
      return <Redirect push to="/swap-erlc" />;
  }else {

    return (
	    <div>
		    <Header titre="Enterprise Marketplace" balance="" />
        <Etapes pos="1" />
        <div className="full_screen_2">
        <div className="ph_top center">
          <p><i>We need you to connect with metamask in order to whitelist your Ethereum address at the end of the KYC/KYB process.</i></p>
          <p><i>Metamask will prove that you have the full ownership of this address by signing a message.</i></p>
        </div>
			<div className="welcome_screen center metamask">

        <a href="#" className={"btn_round anime "+(metamask_find ? 'show' : 'hide')} onClick={() => getAccount()}>Connect with Metamask</a>
        <p className={"bold "+(!metamask_find ? 'show' : 'hide')}>You must install Metamask to continue</p>
        <p className="bold">What is Metamask ?  <a href="https://metamask.io/" target="_blank">Click here</a></p>
			</div>
      </div>
      <div className={"alert anime "+(!alertVisible ? 'cache' : '')}><p><i className="fas fa-exclamation"></i> <b>Failed :</b> {message}</p></div>
      <Warning show="0" />
		</div>
    );
    }

}

export default withRouter(Authentification);
