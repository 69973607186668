import React, {useState} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';

import Header from '../components/header';
import Warning from '../components/warning';


function Login(props) {


    const [redirect, setRedirect] = useState(0);


	  if (redirect) {
		  return <Redirect push to="/" />;
	}else {

    return (
	    <div>
		    <Header titre="Enterprise Marketplace" balance="" />
			<div className="welcome_screen center full_screen">
				<h1 className="h1_welcome center">Onboarding for Enterprise Marketplace and eRLC swap offering</h1>
        <p>Based on the Ethereum blockchain, the iExec Enterprise Marketplace will allow for the peer-to-peer exchange of three types of cloud resources: Computing power, Applications, and Datasets. Providers can decide the price at which they rent out their cloud resources and consumers pay for the resources they use with zero additional marketplace fees.</p>

        <a href="/authentification" className="btn_round anime">Start the onboarding process</a>
			</div>
      <div className="img_illustration"></div>
      <Warning show="0" />
		</div>
    );
    }

}

export default withRouter(Login);
