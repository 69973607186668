import React from 'react';

function Etapes(props) {
  
    return (
      <div className="d-flex align-items-center justify-content-center head_etape">
        <div className="sep"></div>
        <div className="center">
        <div className="num_etape"><span>1</span></div>
      	<p className={"anime "+(props.pos >= 1 ? 'active' : '')}>Ethereum Authentification</p>
        </div>
        <div className="center">
        <div className="num_etape"><span>2</span></div>
      	<p className={"anime "+(props.pos >= 2 ? 'active' : '')}>Personal Identification</p>
        </div>
        <div className="center">
        <div className="num_etape"><span>3</span></div>
      	<p className={"anime "+(props.pos >= 3 ? 'active' : '')}>Additional Information</p>
        </div>
        <div className="center">
        <div className="num_etape"><span>4</span></div>
      	<p className={"anime "+(props.pos >= 4 ? 'active' : '')}>Payment Verification</p>
        </div>
        <div className="center">
        <div className="num_etape"><span>5</span></div>
        <p className={"anime "+(props.pos >= 5 ? 'active' : '')}>Swap eRLC</p>
        </div>
      </div>
    );
  }


export default Etapes;
