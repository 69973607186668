import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import { API_URL } from '../config';

import Header from '../components/header';
import Warning from '../components/warning';
import Etapes from '../components/head_etapes';


function Others_infos(props) {

  const [validForm, setValidForm] = useState(0);
  const [redirect, setRedirect] = useState(0);

  const [check, setCheck] = useState(false);
  const [mail, setMail] = useState("");
  const [invest, setInvest] = useState("");

  useEffect(() => {
    if(mail !== "" && invest !== "" && check) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( re.test(mail) ) {
        setValidForm(1);
      }else {
        setValidForm(0);
      }
    }else {
      setValidForm(0);
    }
  }, [check, mail, invest])

  useEffect(() => {
    //let history = useHistory();

    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/check');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status !== 200) {
          go_home();
        }
      }
    }

  }, []);

  let history = useHistory();

  function go_home() {
    history.push("/");
  }



  function setOthersInfos() {
    var params = {
      "mail": mail,
      "invest" : invest,
      "honor" : check,
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/infos');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status === 200) {
          var response = JSON.parse(log_api.response);
          console.log(response);

          setRedirect(1);
        }else {
          console.log("Error !");
        }
      }
    }

  }


  if (redirect) {
    return <Redirect push to="/payment-verification" />;
}else {
  return (
    <div>
      <Header titre="Enterprise Marketplace" balance="" />
      <Etapes pos="3" />
      <div className={"ph_top center "}>
        <h1 className="h1_welcome center">Additional Informations</h1>
      </div>

      <div className={"others_infos_screen"}>
        <div className="une_ligne d-flex align-items-center justify-content-between">
          <p>E-mail</p>
          <input type="mail" name="name" className="email input_txt" value={mail} onChange={e => setMail(e.target.value)} />
        </div>


        <div className="une_ligne d-flex align-items-center justify-content-between">
          <p>How much will you invest ? (in $)</p>
          <input type="number" name="name" className="email input_txt" value={invest} onChange={e => setInvest(e.target.value)} />
        </div>
        <div className="une_ligne d-flex align-items-center justify-content-between">
          <p>Honor Attestation </p>
          <label>
            <input
            name="honor_check"
            type="checkbox"
            className="input_check"
            checked={check}
            onChange={e => setCheck(!check)} />
            I declare on honor that all the informations above are right and that I am not an US person.
          </label>
        </div>
      </div>
      <div className="center others_infos_screen">
        <a href="#" className={"btn_round anime "+(!validForm ? 'disable' : '')} onClick={() => setOthersInfos()}>Next</a>
      </div>


      <Warning show="0" />
    </div>
  );
}



}

export default withRouter(Others_infos);
