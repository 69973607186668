import React, {useState, useEffect} from 'react';
import {
  Link,
  withRouter
} from "react-router-dom";
import { Redirect } from 'react-router';
import { useHistory } from "react-router-dom";
import { API_URL } from '../config';

import {SynapsClient} from '@synaps-io/synaps-client-sdk-js';

import Header from '../components/header';
import Warning from '../components/warning';
import Etapes from '../components/head_etapes';

import individuel from '../assets/individual.svg';
import entreprise from '../assets/entreprise.svg';

function Identity(props) {

  const [redirect, setRedirect] = useState(0);
  const [type_user, setType_user] = useState(0);
  const [etape, setEtape] = useState(0);
  const [session_synaps, setSession_synaps] = useState("");

  const [alertVisible, setAlertVisible] = useState(0);
  const [message, setMessage] = useState("KYC/KYB not completed");

  useEffect(() => {
    if(alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    }
  }, [alertVisible]);


  useEffect(() => {
    //let history = useHistory();

    var params = {
      "adr_eth" : localStorage.getItem('adr_eth')
    }

    var log_api = new XMLHttpRequest();
    log_api.open('POST', API_URL+'/check');
    log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    log_api.send(JSON.stringify(params));
    log_api.onreadystatechange = function() {
      if(log_api.readyState === 4) {
        if(log_api.status !== 200) {
          go_home();
        }
      }
    }

  }, []);

  let history = useHistory();

  function go_home() {
    history.push("/");
  }

  const launchSynaps = (type_compte) => {
    //console.log(localStorage.getItem('session_id'));
    setEtape(1);

    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "type_compte":type_compte
    }

    var init_synaps = new XMLHttpRequest();
    init_synaps.open('POST', API_URL+'/synaps/init');
    init_synaps.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    init_synaps.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    init_synaps.send(JSON.stringify(params));
    init_synaps.onreadystatechange = function() {
      if(init_synaps.readyState === 4) {
        if(init_synaps.status === 200) {

          var response = JSON.parse(init_synaps.responseText);
          if(type_compte === 1) {
            setSession_synaps(response.session_id);
            localStorage.setItem('session_id', response.session_id);
            const Synaps = new SynapsClient(response.session_id, 'workflow');
            Synaps.init({type: "embed",});
          }else if(type_compte === 2) {
            setSession_synaps(response.session_id);
            localStorage.setItem('session_id', response.session_id);
            const Synaps = new SynapsClient(response.session_id, 'corporate');
            Synaps.init({type: "embed",});
          }
        }
      }
    }

  }

  const check_progress = () => {
    var params = {
      "adr_eth" : localStorage.getItem('adr_eth'),
      "type_compte":type_user,
      "session_id":session_synaps
    }

    var check_synaps = new XMLHttpRequest();
    check_synaps.open('POST', API_URL+'/synaps/check');
    check_synaps.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    check_synaps.setRequestHeader('Authorization', localStorage.getItem("token_user"));
    check_synaps.send(JSON.stringify(params));
    check_synaps.onreadystatechange = function() {
      if(check_synaps.readyState === 4) {
        if(check_synaps.status === 200) {

          var response = JSON.parse(check_synaps.responseText);
          if(type_user === 1) {
            var ok = true;
            response.forEach(element => {
              if(element.state !== "VALIDATED" && element.state !== "PENDING") {
                ok = false;
              }
            });

            if(ok) {
              var params = {
                "session_id": session_synaps,
                "type_compte": type_user,
                "adr_eth" : localStorage.getItem('adr_eth')
              }

              var log_api = new XMLHttpRequest();
              log_api.open('POST', API_URL+'/identity');
              log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
              log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
              log_api.send(JSON.stringify(params));
              log_api.onreadystatechange = function() {
                if(log_api.readyState === 4) {
                  if(log_api.status === 200) {
                    var response = JSON.parse(log_api.response);

                    setRedirect(1);
                  }else {
                    console.log("Error !");
                  }
                }
              }
            }else {
              setAlertVisible(1);
            }
          }else if(type_user === 2) {
            if(response.corporate_status === "PENDING" && response.identity_status === "PENDING" && response.liveness_status === "PENDING" && response.residency_status === "PENDING") {
              var params = {
                "session_id": session_synaps,
                "type_compte": type_user,
                "adr_eth" : localStorage.getItem('adr_eth')
              }

              var log_api = new XMLHttpRequest();
              log_api.open('POST', API_URL+'/identity');
              log_api.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
              log_api.setRequestHeader('Authorization', localStorage.getItem("token_user"));
              log_api.send(JSON.stringify(params));
              log_api.onreadystatechange = function() {
                if(log_api.readyState === 4) {
                  if(log_api.status === 200) {
                    var response = JSON.parse(log_api.response);
                    setRedirect(1);
                  }else {
                    console.log("Error !");
                  }
                }
              }
            }else {
              setAlertVisible(1);
            }
          }
        }
      }
    }
  }

if (redirect) {
  return <Redirect push to="/additional-informations" />;
}else {
  return (
    <div>
      <Header titre="Enterprise Marketplace" balance="" />
      <Etapes pos="2" />

     <div className={"etapes_identite "+(etape === 0 ? 'show' : 'hide')}>
        <div className="center ph_top">
          <h1 className="h1_welcome center">You are:</h1>
          <p><i>Whether you are a company or an individual, you can proceed the KYC/KYB.</i></p>
          <p><i>Please note that the processes are not the same, be careful to select your correct status.</i></p>

        </div>
        <div className="center choix_identite">
          <div className="d-flex align-items-center justify-content-center">
            <div className={"cadre_type anime center "+(type_user === 1 ? 'sel' : '')} onClick={() => setType_user(1)}>
              <img src={individuel} alt="iExec" />
              <h2 className="anime">An individual</h2>
            </div>

            <div className={"cadre_type anime center "+(type_user === 2 ? 'sel' : '')} onClick={() => setType_user(2)}>
              <img src={entreprise} alt="iExec" />
              <h2 className="anime">An enterprise</h2>
            </div>
          </div>

          <button className={"btn_round anime "+(!type_user ? 'disable' : '')} onClick={() => launchSynaps(type_user)}>Next</button>
        </div>
      </div>

      <div className={"etapes_identite center "+(etape === 1 ? 'show' : 'hide')}>
        <div id="synaps-embed"></div>
        <button className={"btn_next_identity btn_round anime "+(!type_user ? 'disable' : '')} onClick={() => check_progress()}>Next</button>
      </div>

      <div className={"alert anime "+(!alertVisible ? 'cache' : '')}><p><i className="fas fa-exclamation"></i> <b>Failed :</b> {message}</p></div>

      <Warning show="0" />
    </div>
  );
}

}

export default withRouter(Identity);
